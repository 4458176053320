/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "bootstrap/dist/css/bootstrap.css"
import "./src/styles/global.scss"

import "jquery/dist/jquery.slim"
import "bootstrap/dist/js/bootstrap.bundle"
